<template>
  <v-card class="nospace">
    <v-card-text class="nospace">
      <v-data-table
        id="dataTable"
        hide-actions
        :headers="headers"
        :items="dubai.Duabi"
        :disable-initial-sort="true"
      >
        <template slot="items" slot-scope="props">
          <td>{{ props.item.destination }}</td>
          <td>{{ props.item.request }}</td>
          <td>{{ props.item.confirmed }}</td>
          <td>{{ props.item.pending }}</td>
          <td>{{ props.item.cancel }}</td>
          <td>{{ props.item.total }}</td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import { StorageKeys } from "../../constants/constants";
import { dubaiDashboardList } from "@/constants/datatable-headers.js";
export default {
  created() {},
  data() {},
  props: {
    dubai: Object,
  },
  data() {
    return {
      headers: dubaiDashboardList,
      items: [],
    };
  },
};
</script>
<style lang="scss">
#dataTable .theme--light.v-datatable thead th.column.sortable .v-icon {
  opacity: 0.3 !important;
  color: white !important;
  margin-left: 10px !important;
}
#dataTable .theme--light.v-datatable thead th.column.sortable.active .v-icon {
  opacity: 1 !important;
  color: white !important;
  margin-left: 10px !important;
}
.v-datatable thead th.column.sortable .v-icon {
  opacity: 0.3 !important;
}
</style>
