<template>
  <v-container fluid>
    <v-card raised v-if="showScreen">
      <v-progress-linear
        indeterminate
        color="trukkin-theme darken-2"
        v-show="loading"
      ></v-progress-linear>
      <v-card-title class="bg-clr">
        <v-layout>
          <v-flex xs3>
            <span class="muted"></span>
            <v-menu
              ref="jobPickupTimeBool"
              lazy
              v-model="jobPickupTimeBool"
              :close-on-content-click="false"
              transition="scale-transition"
              full-width
              :nudge-right="40"
              min-width="290px"
              :return-value.sync="startDate"
            >
              <v-text-field
                slot="activator"
                :label="'Filter By start date'"
                required
                v-model="startDate"
                prepend-icon="event"
                readonly
              ></v-text-field>
              <v-date-picker
                v-model="startDate"
                class="minHeight"
                @change="
                  $refs.jobPickupTimeBool.save(startDate);
                  startDateFilter();
                "
                no-title
                scrollable
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs4 class="reset-btn">
            <v-btn
              flat
              class="reset-btn-color"
              prepend-icon
              color="orange darken-1"
              @click.native="reset()"
            >
              Reset
              <v-icon color="orange">donut_large</v-icon>
            </v-btn>
          </v-flex>
          <v-spacer />
        </v-layout>
        <v-spacer />
      </v-card-title>
      <v-tabs
        fixed-tabs
        v-model="currentTab"
        background-color="darkorange"
        dark
      >
        <v-tab
          :href="`#Dubai`"
          v-if="selectedCountry.indexOf('UAE') >= 0"
          @click="viewTab('Dubai')"
          >Dubai Bookings</v-tab
        >
        <v-tab
          :href="`#Dubai`"
          v-if="selectedCountry.indexOf('UAE LA') >= 0"
          @click="viewTab('Dubai')"
          >Dubai LA Bookings</v-tab
        >
        <v-tab
          :href="`#KSA`"
          v-if="selectedCountry.indexOf('KSA') >= 0"
          @click="viewTab('KSA')"
          >KSA Bookings</v-tab
        >
        <v-tab
          :href="`#KAR`"
          v-if="selectedCountry.indexOf('PAK') >= 0"
          @click="viewTab('KAR')"
          >KAR Bookings</v-tab
        >
        <v-tab
          :href="`#LHR`"
          v-if="selectedCountry.indexOf('PAK') >= 0"
          @click="viewTab('LHR')"
          >LHR Bookings</v-tab
        >
      </v-tabs>

      <v-layout row v-on:fetchDashboard="load">
        <v-flex
          v-if="selectedCountry.indexOf('UAE') >= 0 && currentTab == 'Dubai'"
          class="m-t-20"
          md12
        >
          <datatable :dubai="items" />
        </v-flex>
        <v-flex
          v-if="selectedCountry.indexOf('UAE LA') >= 0 && currentTab == 'Dubai'"
          class="m-t-20"
          md12
        >
          <datatable :dubai="items" />
        </v-flex>
        <v-flex
          v-if="selectedCountry.indexOf('KSA') >= 0 && currentTab == 'KSA'"
          class="m-t-20"
          md12
        >
          <datatable2 :KSA="items" />
        </v-flex>
        <v-flex
          class="m-t-20"
          v-if="selectedCountry.indexOf('PAK') >= 0 && currentTab == 'KAR'"
          md12
        >
          <datatable3 :KAR="items" />
        </v-flex>
        <v-flex
          class="m-t-20"
          v-if="selectedCountry.indexOf('PAK') >= 0 && currentTab == 'LHR'"
          md12
        >
          <datatable4 :LHR="items" />
        </v-flex>
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import datatable from "./datatable";
import datatable2 from "./datatable2";
import datatable3 from "./datatable3";
import datatable4 from "./datatable4";
import { bookingStats } from "@/constants/api-urls.js";
import Swal from "sweetalert2";
import { StorageKeys } from "../../constants/constants";
import checkPermissionsMixin from "@/mixins/checkPermissionsMixin";
import { EventBus } from "../../event-bus.js";
import moment from "moment";
export default {
  mixins: [checkPermissionsMixin],
  created() {
    // if (localStorage.getItem("searchBookingstats") !== null) {
    //   this.startDate = localStorage.searchBookingstats;
    //   this.startDateFilter();
    // }
    if (localStorage.getItem("workingCountries") !== null) {
      this.selectedCountry = [];
      let workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          this.selectedCountry.push(key.value);
        }
      });
    }

    EventBus.$on("countryChanged", (val) => {
      this.selectedCountry = val;
      //this.reset();
      if (this.startDate) {
        this.startDateFilter();
      } else {
        this.fetchDashboard();
      }
    });
    this.fetchDashboard();
  },
  mounted() {
    this.checkTabs();
  },

  components: {
    datatable,
    datatable2,
    datatable3,
    datatable4,
  },
  data: () => ({
    active: "0",
    tabStatus: "dubai",
    showScreen: true,
    dialog: false,
    loading: true,
    selectedCountry: [],
    startDate: null,

    items: { Duabi: [], KSA: [], LHR: [], KAR: [] },
    jobPickupTimeBool: false,
    currentTab: "Dubai",
  }),
  methods: {
    checkTabs() {
      if (
        this.selectedCountry.includes("PAK") &&
        this.selectedCountry.includes("KSA")
      ) {
        this.currentTab = "KAR";
        this.loading = false;
        return;
      } else if (
        !this.selectedCountry.includes("PAK") &&
        !this.selectedCountry.includes("UAE")
      ) {
        this.currentTab = "KSA";
        this.loading = false;
        return;
      } else if (
        this.selectedCountry.includes("PAK") &&
        this.selectedCountry.includes("KSA")
      ) {
        this.currentTab = "KSA";
        this.loading = false;
        return;
      } else if (this.selectedCountry.includes("KSA")) {
        this.currentTab = "KSA";
        this.loading = false;
        return;
      } else {
        this.currentTab = "Dubai";
        this.loading = false;
        return;
      }
    },
    viewTab(flag) {
      this.currentTab = flag;
    },
    load(event) {
      this.loading = event;
    },
    reset() {
      if (this.startDate) {
        //  localStorage.removeItem("searchBookingstats", this.startDate);
        this.startDate = "";
        this.fetchDashboard();
      }
    },

    getDefaultProfile() {
      return Object.assign(
        {},
        {
          jobId: "NA",
          waybillNumber: "",
          from: null,
          to: null,
          amount: null,
          notes: null,
          driverName: null,
          waybillNumber: null,
          status: null,
          customerName: null,
          customerCurrency: null,
          currentStatus: null,
          assignmentsRequired: null,
          timeZoneId: null,
          startDate: null,
          deliveryDate: null,
          otherDetails: null,
          packingType: null,
          cargoWeight: null,
          quantity: null,
          sourceCity: null,
          sourceCountry: null,
          destinationCity: null,
          destinationCountry: null,
          natureOfCargo: null,
          orderType: null,
          truckType: null,
          status: -2,
        }
      );
    },

    fetchDashboard() {
      this.loading = true;
      let url = bookingStats;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {};

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.items = response.data.data;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.x.error = "Failed to update inquiry status";
        }
      );
    },
    startDateFilter() {
      // localStorage.setItem("searchBookingstats", this.startDate);
      this.jobPickupTimeBool = false;
      this.loading = true;
      let url = bookingStats;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let startDate = "";
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = { startDate: this.startDate };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.items = response.data.data;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.x.error = "Failed to update inquiry status";
        }
      );
    },
  },
  watch: {
    selectedCountry(val) {
      switch (val[0]) {
        case "PAK":
          this.currentTab = "KAR";
          break;
        case "KSA":
          this.currentTab = "KSA";
          break;
        case "UAE":
          this.currentTab = "Dubai";
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style>
.m-t-20 {
  margin-top: 20px;
}
.minHeight {
  min-height: 300px;
}
</style>
